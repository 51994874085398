import * as React from "react"
import Layout from "../components/Layout/Layout"
import TitleStyle from "../components/TitleStyle/TitleStyle"
import Content from "../components/Content/Content"
import Seo from "../components/SEO/SEO"

const GDPRPage = () => {
  return (
    <Layout>
    <Seo title="GDPR" />
      <Content>
        <TitleStyle>Polityka prywatności</TitleStyle>
        <div>
            <p>
                1. Informacje ogólne <br/>
                Operatorem serwisu oraz Administratorem danych osobowych jest: MERCE<br/>
                Adres kontaktowy poczty elektronicznej operatora: lorem@ipsum<br/>
                Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w Serwisie.<br/>
                Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”).<br/>
            </p>
            <p>
                2. Wybrane metody ochrony danych stosowane przez Operatora<br/>
                Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym serwerze.<br/>
                Dane osobowe przechowywane w bazie danych są zaszyfrowane w taki sposób, że jedynie posiadający Operator klucz może je odczytać. Dzięki temu dane są chronione na wypadek wykradzenia bazy danych z serwera.<br/>
                Hasła użytkowników są przechowywane w postaci hashowanej. Funkcja hashująca działa jednokierunkowo - nie jest możliwe odwrócenie jej działania, co stanowi obecnie współczesny standard w zakresie przechowywania haseł użytkowników.<br/>
                Operator okresowo zmienia swoje hasła administracyjne.<br/>
                W celu ochrony danych Operator regularnie wykonuje kopie bezpieczeństwa.<br/>
                Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego oprogramowania, wykorzystywanego przez Operatora do przetwarzania danych osobowych, co w szczególności oznacza regularne aktualizacje komponentów programistycznych.<br/>
            </p>
            <p>
                3. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych<br/>
                W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich grup odbiorców:<br/>
                firma hostingowa na zasadzie powierzenia<br/>
                upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w celu realizacji celu działania strony<br/>
                firmy, świadczące usługi marketingu na rzecz Administratora<br/>
                Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż jest to konieczne do wykonania związanych z nimi czynności określonych osobnymi przepisami. W odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej niż przez 3 lata.<br/>
                Przysługuje Ci prawo żądania od Administratora:<br/>
                dostępu do danych osobowych Ciebie dotyczących,<br/>
                ich sprostowania,<br/>
                usunięcia,<br/>
                ograniczenia przetwarzania,<br/>
                oraz przenoszenia danych.<br/>
                Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w pkt 3.3 c) wobec przetwarzania danych osobowych w celu wykonania prawnie uzasadnionych interesów realizowanych przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub obrony roszczeń.<br/>
                Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.<br/>
                Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu.<br/>
                W stosunku do Ciebie mogą być podejmowane czynności polegające na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora marketingu bezpośredniego.<br/>
                Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy ich poza teren Unii Europejskiej.<br/>
            </p>
            <p>
                4. Informacje w formularzach<br/>
                Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.<br/>
                Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).<br/>
                Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony zawierającej formularz.<br/>
                Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np. w celu dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp. Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do czego on służy.<br/>

            </p>
            <p>
                5. Logi Administratora<br/>
                Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.<br/>
            </p>
            <p>
                6. Istotne techniki marketingowe<br/>
                Operator stosuje analizę statystyczną ruchu na stronie, poprzez Google Analytics (Google Inc. z siedzibą w USA). Operator nie przekazuje do operatora tej usługi danych osobowych, a jedynie zanonimizowane informacje. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i edytować informacje wynikające z plików cookies przy pomocy narzędzia: https://www.google.com/ads/preferences/
            </p>
            <p>
                7. Informacja o plikach cookies<br/>
                Serwis korzysta z plików cookies.<br/>
                Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.<br/>
                Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.
                Pliki cookies wykorzystywane są w następujących celach:<br/>
                utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;
                realizacji celów określonych powyżej w części "Istotne techniki marketingowe";<br/>
                W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.<br/>
                Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.<br/>
                Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.<br/>
                Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą być również przez współpracujące z operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).<br/>
            </p>
            <p>
                8. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?<br/>
                Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www
                W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę internetową, której używasz.
            </p>
        </div>
      </Content>
        
    </Layout>
  )
}

export default GDPRPage
