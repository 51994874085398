import styled from 'styled-components'

const TitleStyle = styled.h3`
    font-family: ${({ theme }) => theme.typography.default};
    font-weight: 400;
    font-size: 40px;
    color: ${({ theme }) => theme.colors.black};
    line-height: 1.5em;
    text-align: center;
    margin-top: 15px;
`

export default TitleStyle
